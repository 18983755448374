import { useRoute } from '@/lib/composables/utils/useRouter';
import { createComponent } from '@/lib/vue';
import { AppState } from '@/store/modules/root.store';

export const AuthLayout = createComponent({
  name: 'AuthLayout',
  setup(props, ctx) {
    AppState.loadingOff();

    return () => (
      <div id="AuthLayout" class="flex justify-center items-center">
        <v-fade-transition mode="out-in">
          <router-view key={useRoute().fullPath} />
        </v-fade-transition>
      </div>
    );
  },
});

export default AuthLayout;
