import { Logo } from '@/components/icon/Logo';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { createComponent } from '@/lib/vue';

export const AuthCard = createComponent({
  name: 'AuthCard',
  setup(props, ctx) {
    return () => {
      const defaultSlot = ctx.slots.default?.();

      return (
        <v-sheet
          id="AuthCard"
          elevation="12"
          color={MyTheme.white}
          class="flex flex-col max-w-lg rounded min-w-75"
        >
          <v-sheet
            id="card-title"
            color={MyTheme.cyan800}
            class="flex items-center justify-center flex-initial p-4 sm:rounded-t sm:rounded-b-none"
          >
            <Logo type="white" />
          </v-sheet>

          <div class="flex flex-col px-6 pt-4 pb-6">{defaultSlot}</div>
        </v-sheet>
      );
    };
  },
});

export default AuthCard;
