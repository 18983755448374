import { useFormPage } from '@/lib/composables/form/useFormPage';
import { useAuthInfo } from '@/lib/composables/useAuthInfo';
import { AuthSignUpService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { AppState } from '@/store/modules/root.store';
import { IAuthSignUpPart2Model } from '@/typings';
import { computed } from '@vue/composition-api';
import { AuthCard } from '../../components/AuthCard';
import { AuthSignUpPart2Form } from '../../components/signUp/AuthSignUpPart2Form';

export default createComponent({
  name: 'AuthSignUpPart2Page',
  setup(props, ctx) {
    const { $user } = useAuthInfo();
    const $nome = computed(() => $user.value?.nome);

    const { page, handleSubmit } = useFormPage<IAuthSignUpPart2Model>({
      ctx,
      submitCallback(model) {
        return AuthSignUpService.signUpPart2(model);
      },
    });

    AppState.loadingOff();

    return () => (
      <AuthCard id="AuthSignUpPart2Page">
        <h1 class="mb-6 text-center text-headline">Configurações iniciais</h1>

        <AuthSignUpPart2Form
          nome={$nome.value}
          page={page}
          onSubmit={handleSubmit}
        />

        <div class="mt-4 text-center text-body-sm">
          Ao clicar no botão acima você concorda com nossos
          <a
            href="https://www.clinically.com.br/termos-de-uso"
            target="_blank"
            class="ml-1 no-underline"
          >
            termos de uso
          </a>
        </div>
      </AuthCard>
    );
  },
});
