import { useFormPage } from '@/lib/composables/form/useFormPage';
import { AuthLoginService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { IAuthLoginModel } from '@/typings';
import { AuthCard } from '../components/AuthCard';
import { AuthLinks } from '../components/AuthLinks';
import { AuthLoginForm } from '../components/forms/AuthLoginForm';

export default createComponent({
  name: 'AuthLoginPage',
  setup(props, ctx) {
    const { page, handleSubmit } = useFormPage({
      ctx,
      submitCallback(model: IAuthLoginModel) {
        return AuthLoginService.login(model);
      },
    });

    return () => (
      <AuthCard>
        <h1 class="text-headline text-center mb-4">Acesse sua conta</h1>

        <AuthLoginForm page={page} onSubmit={handleSubmit} />

        <AuthLinks class="mt-4" />
      </AuthCard>
    );
  },
});
