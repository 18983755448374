import { UserTipo } from '@/typings';
import { computed } from '@vue/composition-api';
import { useState } from '.';

export function useAuthInfo() {
  const $user = useState(s => s.auth.user);

  const $clinica = useState(s => s.auth.clinica);

  const $userIsProfissionalSaude = computed(
    () => $user.value?.tipo === UserTipo.PROFISSIONAL_SAUDE,
  );

  const $userIsRecepcionista = computed(
    () => $user.value?.tipo === UserTipo.RECEPCIONISTA,
  );

  const $userIsAdmin = computed(
    () => !!$user.value?.adminAccount || !!$clinica.value?.adminClinica,
  );

  return {
    clinicaId: $clinica.value?.id,
    $user,
    $clinica,
    $userIsProfissionalSaude,
    $userIsRecepcionista,
    $userIsAdmin,
  };
}
