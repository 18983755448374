import { createComponent } from '@/lib/vue';
import { PropType } from 'vue';

type ILogoType = 'white' | 'primary';

export const Logo = createComponent({
  name: 'Logo',
  props: {
    type: { type: String as PropType<ILogoType>, required: true },
    width: { type: Number, default: 260 },
  },
  setup(props, ctx) {
    return () => (
      <img
        src={`/logo/logo-${props.type}.svg`}
        width={props.width}
        alt="Clinically Logo"
      />
    );
  },
});
