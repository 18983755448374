import { CircularLoading } from '@/components/loading/CircularLoading';
import { useValue } from '@/lib/composables';
import { useFormPage } from '@/lib/composables/form/useFormPage';
import { useRoute, useRouter } from '@/lib/composables/utils/useRouter';
import { Validate } from '@/lib/helpers/error';
import { AuthConviteService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { IAuthConviteModel } from '@/typings';
import { onMounted } from '@vue/composition-api';
import { AuthCard } from '../components/AuthCard';
import { AuthConviteForm } from '../components/forms/AuthConviteForm';

export default createComponent({
  name: 'AuthConvitePage',
  setup(props, ctx) {
    const { token } = useRoute().params;

    const [$loading, setLoading] = useValue(true);
    const [$tokenValid, setTokenValid] = useValue(true);

    if (!token) {
      return useRouter().replace(Routes.auth.login);
    }

    onMounted(async () => {
      setTokenValid(
        !!(await AuthConviteService.checkConvite({
          conviteToken: token,
        })),
      );
      setLoading(false);
    });

    return () => (
      <AuthCard>
        <CircularLoading loading={$loading.value}>
          {$tokenValid.value ? <ConviteForm token={token} /> : <LinkExpirado />}
        </CircularLoading>
      </AuthCard>
    );
  },
});

const ConviteForm = createComponent<{ token: string }>({
  name: 'AuthConvitePageConviteForm',
  props: {
    token: { type: String, required: true },
  },
  setup(props, ctx) {
    const { page, handleSubmit } = useFormPage<IAuthConviteModel>({
      ctx,
      submitCallback(model) {
        return AuthConviteService.convite({
          conviteToken: props.token,
          password: Validate.require(model.password, 'password'),
        });
      },
    });

    return () => (
      <div class="flex flex-col">
        <h1 class="text-center text-headline">Aceitar convite</h1>

        <h2 class="py-2 text-center text-gray-600 text-subtitle">
          Para aceitar o convite preencha os campos abaixo e clique em aceitar
          convite.
        </h2>

        <AuthConviteForm page={page} onSubmit={handleSubmit} />
      </div>
    );
  },
});

const LinkExpirado = createComponent({
  name: 'AuthConvitePageLinkExpirado',
  setup() {
    return () => (
      <div>
        <h1 class="text-center text-headline text-error">
          Link para convite de usuário já expirou
        </h1>
      </div>
    );
  },
});
