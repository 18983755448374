import { CircularLoading } from '@/components/loading/CircularLoading';
import { useValue } from '@/lib/composables';
import { useFormPage } from '@/lib/composables/form/useFormPage';
import { useRoute, useRouter } from '@/lib/composables/utils/useRouter';
import { Validate } from '@/lib/helpers/error';
import { AuthEsqueceuSenhaService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { IAuthEsqueceuSenhaPart2Model } from '@/typings';
import { onMounted } from '@vue/composition-api';
import { AuthCard } from '../../components/AuthCard';
import { AuthEsqueceuSenhaPart2Form } from '../../components/esqueceuSenha/AuthEsqueceuSenhaPart2Form';

export default createComponent({
  name: 'AuthEsqueceuSenhaPart2Page',
  setup(props, ctx) {
    const { token } = useRoute().params;
    const [$loading, setLoading] = useValue(true);
    const [$tokenValid, setTokenValid] = useValue(true);

    if (!token) {
      return useRouter().replace(Routes.auth.login);
    }

    onMounted(async () => {
      setTokenValid(
        !!(await AuthEsqueceuSenhaService.checkEsqueceuSenha({
          esqueceuSenhaToken: token,
        })),
      );
      setLoading(false);
    });

    return () => (
      <AuthCard>
        <CircularLoading loading={$loading.value}>
          {$tokenValid.value ? (
            <NewPasswordForm token={token} />
          ) : (
            <LinkExpirado />
          )}
        </CircularLoading>
      </AuthCard>
    );
  },
});

const NewPasswordForm = createComponent<{ token: string }>({
  name: 'AuthEsqueceuSenhaPart2PageNewPasswordForm',
  props: {
    token: { type: String, required: true },
  },
  setup(props, ctx) {
    const { page, handleSubmit } = useFormPage<IAuthEsqueceuSenhaPart2Model>({
      ctx,
      submitCallback(model) {
        return AuthEsqueceuSenhaService.esqueceuSenhaPart2({
          esqueceuSenhaToken: props.token,
          password: Validate.require(model.password, 'password'),
        });
      },
    });

    return () => (
      <div class="flex flex-col">
        <h1 class="text-center text-headline">Defina sua nova senha</h1>

        <AuthEsqueceuSenhaPart2Form page={page} onSubmit={handleSubmit} />
      </div>
    );
  },
});

const LinkExpirado = createComponent({
  name: 'AuthEsqueceuSenhaPart2PageLinkExpirado',
  setup() {
    const esqueceuSenhaPart1Route = Routes.auth.esqueceuSenhaPart1;

    return () => (
      <div class="flex items-center">
        <h1 class="text-center text-headline text-error">
          Link para recuperação de senha já expirou
        </h1>

        <v-btn color="primary" exact to={esqueceuSenhaPart1Route}>
          Solicitar novamente
        </v-btn>
      </div>
    );
  },
});
