import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { FormHeader } from '@/components/typography/FormHeader';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { ConstantsHelper } from '@/lib/constants/helper';
import { createComponent } from '@/lib/vue';
import {
  IAuthSignUpPart2FormSchema,
  IAuthSignUpPart2Model,
  IForm,
} from '@/typings';
import { onMounted, Ref, SetupContext } from '@vue/composition-api';

interface IProps extends IFormProps {
  nome?: string | null;
}

interface IEvents extends IFormEvents<IAuthSignUpPart2Model> {}

export const AuthSignUpPart2Form = createComponent<IProps, IEvents>({
  name: 'AuthSignUpPart2Form',
  props: {
    page: { type: Object, required: true },
    nome: { type: String },
  },
  setup(props, ctx) {
    const { $form, $schema, emitSubmit } = useForm(props, ctx);

    onMounted(() => {
      if (props.nome) {
        $form.value.model.nome.nome = props.nome;
      }
    });

    return () => (
      <MyForm noSummary form={$form.value} onSubmit={emitSubmit}>
        <FormHeader title="Como deseja ver seu nome?" />

        <FormFields
          form={$form.value}
          schema={$schema.value.nome}
          v-model={$form.value.model.nome}
          maxWidth={600}
        />

        <FormHeader title="Qual sua profissão?" />

        <FormFields
          form={$form.value}
          schema={$schema.value.profissao}
          v-model={$form.value.model.profissao}
        />

        <FormFields
          form={$form.value}
          hide={!isProfissionalSaude($form)}
          schema={$schema.value.profissionalSaude}
          v-model={$form.value.model.profissionalSaude}
        />

        <FormHeader title="Clínica" />

        <FormFields
          form={$form.value}
          schema={$schema.value.clinica}
          v-model={$form.value.model.clinica}
        />

        <v-btn
          slot="actions_section"
          dark
          block
          large
          type="submit"
          color="primary"
          disabled={$form.value.loading}
          loading={$form.value.loading}
        >
          confirmar e começar a usar
        </v-btn>
      </MyForm>
    );
  },
});

function useForm(props: IProps, ctx: SetupContext) {
  return useFormConfig<IAuthSignUpPart2Model, IAuthSignUpPart2FormSchema>({
    page: props.page,
    initialValue: {
      nome: {
        nome: null,
        tratamento: null,
      },
      profissao: {
        profissao: null,
      },
      profissionalSaude: {
        cbo: null,
        conselho: null,
        conselhoUf: null,
        conselhoRegistro: null,
      },
      clinica: {
        telefoneClinica: null,
      },
    },
    mapSchema: (model, $form) => ({
      nome: {
        tratamento: {
          label: 'Tratamento',
          type: 'select',
          list: ConstantsHelper.tratamentos,
          clearable: true,
          layout: { xs: 4, minWidth: 150 },
        },
        nome: {
          label: 'Nome',
          type: 'text',
          validations: { required: true },
          layout: { xs: 8, minWidth: 230 },
        },
      },
      profissao: {
        profissao: {
          label: 'Profissão',
          type: 'autocomplete',
          list: ConstantsHelper.profissoesMedicina,
          validations: { required: true },
        },
      },
      profissionalSaude: {
        cbo: {
          label: 'Especialidade (CBO-S)',
          type: 'autocomplete',
          items: ConstantsHelper.tiss.cbo,
          clearable: true,
          validations: { required: isProfissionalSaude($form) },
        },
        conselho: {
          label: 'Conselho',
          type: 'autocomplete',
          items: ConstantsHelper.tiss.conselhos,
          layout: { xs: 7 },
          validations: { required: isProfissionalSaude($form) },
        },
        conselhoUf: {
          label: 'UF',
          type: 'select',
          items: ConstantsHelper.estados,
          itemLabel: 'sigla',
          itemValue: 'sigla',
          layout: { xs: 5 },
          validations: { required: isProfissionalSaude($form) },
        },
        conselhoRegistro: {
          label: 'Número no conselho',
          type: 'text',
          maxLength: 15,
          validations: { required: isProfissionalSaude($form) },
        },
      },
      clinica: {
        telefoneClinica: {
          label: 'Telefone',
          type: 'text',
          mask: 'telefone',
          validations: { required: true },
        },
      },
    }),
    ctx,
  });
}

function isProfissionalSaude($form: Ref<IForm<IAuthSignUpPart2Model>>) {
  return ['Médico', 'Dentista', 'Outro profissional de saúde'].includes(
    $form.value.model.profissao.profissao || '',
  );
}
