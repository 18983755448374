import { useFormPage } from '@/lib/composables/form/useFormPage';
import { AuthSignUpService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { IAuthSignUpPart1Model } from '@/typings';
import { AuthCard } from '../../components/AuthCard';
import { AuthLinks } from '../../components/AuthLinks';
import { AuthSignUpPart1Form } from '../../components/signUp/AuthSignUpPart1Form';

export default createComponent({
  name: 'AuthSignUpPart1Page',
  setup(props, ctx) {
    const { page, handleSubmit } = useFormPage<IAuthSignUpPart1Model>({
      ctx,
      submitCallback(model) {
        return AuthSignUpService.signUpPart1(model);
      },
    });

    return () => (
      <AuthCard>
        <h1 class="text-center text-headline">Experimente gratuitamente</h1>

        <h2 class="py-2 text-center text-gray-600 text-subtitle">
          Cadastre-se em apenas 1 minuto.
        </h2>

        <AuthSignUpPart1Form page={page} onSubmit={handleSubmit} />

        <AuthLinks class="mt-4" />
      </AuthCard>
    );
  },
});
