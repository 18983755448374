import { CircularLoading } from '@/components/loading/CircularLoading';
import { QueryGraphql } from '@/graphql/query';
import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import { formatEndereco } from '@/lib/form';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { AuthLoginService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { AppState } from '@/store/modules/root.store';
import { IClinicasPageItem, IMeQuery } from '@/typings';
import { computed } from '@vue/composition-api';
import { AuthCard } from '../components/AuthCard';

export default createComponent({
  name: 'AuthClinicasPage',
  setup(props, ctx) {
    const { $data: $clinicas, $loading: $queryLoading } = useMeQuery();

    AppState.loadingOff();

    const $loading = computed(
      () => $queryLoading.value && !$clinicas.value?.length,
    );

    return () => (
      <AuthCard id="AuthClinicasPage">
        <h1 class="text-center text-headline">Selecione uma das clínicas</h1>

        <CircularLoading loading={$loading.value}>
          <div class="flex flex-col items-center mt-4">
            {$clinicas.value?.map(clinica => (
              <ClinicaCard clinica={clinica} />
            ))}
          </div>
        </CircularLoading>
      </AuthCard>
    );
  },
});

const ClinicaCard = createComponent<{ clinica: IClinicasPageItem }>({
  name: 'AuthClinicasPageClinicaCard',
  props: {
    clinica: { type: Object, required: true },
  },
  setup(props, ctx) {
    function getEndereco(clinica: IClinicasPageItem) {
      const {
        enderecoLogradouro,
        enderecoNumero,
        enderecoComplemento,
        enderecoBairro,
        enderecoCidade,
        enderecoUf,
      } = clinica;

      return formatEndereco({
        cep: null,
        logradouro: enderecoLogradouro,
        numero: enderecoNumero,
        complemento: enderecoComplemento,
        bairro: enderecoBairro,
        cidade: enderecoCidade,
        uf: enderecoUf,
      });
    }

    function handleSelectClinica({
      id,
      nome,
      adminClinica,
      profissionais,
    }: IClinicasPageItem) {
      AuthLoginService.selectClinica({
        adminClinica,
        clinica: {
          id,
          nome,
          profissionais,
        },
      });
    }
    return () => (
      <v-card
        hover
        ripple
        outlined
        id="card"
        class="p-4 mb-4 text-center"
        key={props.clinica.id}
        onClick={() => handleSelectClinica(props.clinica)}
      >
        <div class="flex items-center">
          <v-icon x-large class="icon">
            {MyIcons.clinicaMarker}
          </v-icon>

          <div class="flex flex-col">
            <h2 class="mb-1 text-title text-primary">{props.clinica.nome}</h2>

            <div class="text-body-sm">{getEndereco(props.clinica)}</div>
          </div>
        </div>
      </v-card>
    );
  },
});

function useMeQuery() {
  return useQueryConfig<IMeQuery, IClinicasPageItem[], void>({
    query: QueryGraphql.MeQuery,
    variables() {},
    mapData: result =>
      result?.me?.clinicas?.map(v => ({
        adminClinica: v.adminClinica,
        ...v.clinica,
      })) || [],
  });
}
