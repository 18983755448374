import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { createComponent } from '@/lib/vue';
import { IAuthEsqueceuSenhaPart2Model, IFormSchema } from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps extends IFormProps {}

interface IEvents extends IFormEvents<IAuthEsqueceuSenhaPart2Model> {}

export const AuthEsqueceuSenhaPart2Form = createComponent<IProps, IEvents>({
  name: 'AuthEsqueceuSenhaPart2Form',
  props: {
    page: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { $form, $schema, emitSubmit } = useForm(props, ctx);

    return () => (
      <MyForm noSummary form={$form.value} onSubmit={emitSubmit}>
        <FormFields
          form={$form.value}
          schema={$schema.value}
          v-model={$form.value.model}
        />

        <v-btn
          slot="actions_section"
          dark
          block
          large
          type="submit"
          color="primary"
          disabled={$form.value.loading}
          loading={$form.value.loading}
        >
          atualizar senha
        </v-btn>
      </MyForm>
    );
  },
});

function useForm(props: IProps, ctx: SetupContext) {
  return useFormConfig<
    IAuthEsqueceuSenhaPart2Model,
    IFormSchema<IAuthEsqueceuSenhaPart2Model>
  >({
    page: props.page,
    initialValue: {
      password: null,
      confirmPassword: null,
    },
    mapSchema: () => ({
      password: {
        label: 'Nova senha',
        type: 'password',
        validations: {
          required: true,
          minLength: 6,
        },
      },
      confirmPassword: {
        label: 'Confirmar senha',
        type: 'password',
        validations: {
          required: true,
          confirmPassword: {
            field: 'confirmPassword',
            fieldToConfirm: 'password',
          },
        },
      },
    }),
    ctx,
  });
}
