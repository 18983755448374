import { useValue } from '@/lib/composables';
import { useFormPage } from '@/lib/composables/form/useFormPage';
import { AuthEsqueceuSenhaService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { IAuthEsqueceuSenhaPart1Model, IFormPage } from '@/typings';
import { AuthCard } from '../../components/AuthCard';
import { AuthLinks } from '../../components/AuthLinks';
import { AuthEsqueceuSenhaPart1Form } from '../../components/esqueceuSenha/AuthEsqueceuSenhaPart1Form';

export default createComponent({
  name: 'AuthEsqueceuSenhaPart1Page',
  setup(props, ctx) {
    const [$email, setEmail] = useValue<string | null>(null);

    const { page, handleSubmit } = useFormPage<IAuthEsqueceuSenhaPart1Model>({
      ctx,
      async submitCallback(model) {
        await AuthEsqueceuSenhaService.esqueceuSenhaPart1(model);

        setEmail(model.email);
      },
    });

    return () => (
      <AuthCard>
        {!$email.value ? (
          <Part1Form page={page} handleSubmit={handleSubmit} />
        ) : (
          <Confirmacao email={$email.value} />
        )}
      </AuthCard>
    );
  },
});

const Confirmacao = createComponent<{ email: string }>({
  name: 'AuthEsqueceuSenhaPart1PageConfirmacao',
  props: {
    email: { type: String, required: true },
  },
  setup({ email }) {
    return () => (
      <div>
        <h1 class="text-center text-headline text-success">
          Solicitação realizada com sucesso!
        </h1>

        <h2 class="py-2 text-center text-gray-600 text-subtitle">
          Verifique o email {email}. Você receberá um email com instruções de
          recuperação de senha.
        </h2>
      </div>
    );
  },
});

const Part1Form = createComponent<{
  page: IFormPage;
  handleSubmit: (model: IAuthEsqueceuSenhaPart1Model) => Promise<void>;
}>({
  name: 'AuthEsqueceuSenhaPart1PagePart1Form',
  props: {
    page: { type: Object, required: true },
    handleSubmit: { type: Function, required: true },
  },
  setup(props, ctx) {
    return () => (
      <div>
        <h1 class="text-center text-headline">Esqueceu sua senha?</h1>

        <h2 class="py-2 text-center text-gray-600 text-subtitle">
          Iremos enviar um email para recuperação sua senha.
        </h2>

        <AuthEsqueceuSenhaPart1Form
          page={props.page}
          onSubmit={props.handleSubmit}
        />

        <AuthLinks class="mt-4" />
      </div>
    );
  },
});
