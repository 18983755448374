import { CircularLoading } from '@/components/loading/CircularLoading';
import { useValue } from '@/lib/composables';
import { useRoute, useRouter } from '@/lib/composables/utils/useRouter';
import { AuthConfirmarEmailService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { computed, onMounted } from '@vue/composition-api';
import { AuthCard } from '../components/AuthCard';

export default createComponent({
  name: 'AuthConfirmarEmailPage',
  props: {
    value: { type: String },
  },
  setup(props, ctx) {
    const [$loading, setLoading] = useValue(true);
    const [$success, setSuccess] = useValue(false);

    const successMessage = 'Seu email foi confirmado com sucesso';
    const failMessage = 'Link para confirmação de email já expirou';

    const $message = computed(() =>
      $success.value ? successMessage : failMessage,
    );

    const $messageClass = computed(() => [
      'text-headline text-center',
      $success.value ? 'text-success' : 'text-error',
    ]);

    async function confirmEmail(emailToken: string) {
      return AuthConfirmarEmailService.confirmEmail(emailToken);
    }
    onMounted(async () => {
      const { token } = useRoute().params;

      if (!token) {
        return useRouter().replace(Routes.auth.login);
      }

      setSuccess(await confirmEmail(token));
      setLoading(false);
    });

    return () => (
      <AuthCard>
        <div class="flex items-center justify-center">
          <CircularLoading loading={$loading.value}>
            <h1 class={$messageClass.value}>{$message.value}</h1>
          </CircularLoading>
        </div>
      </AuthCard>
    );
  },
});
