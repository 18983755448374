import { useRoute } from '@/lib/composables/utils/useRouter';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { computed } from '@vue/composition-api';

export const AuthLinks = createComponent({
  name: 'AuthLinks',
  setup(props, ctx) {
    const loginRoute = Routes.auth.login;
    const signUpRoute = Routes.auth.signUpPart1;
    const esqueceuSenhaRoute = Routes.auth.esqueceuSenhaPart1;

    const $isLogin = computed(() => Routes.auth.isLogin(useRoute()));
    const $isCadastro = computed(() => Routes.auth.isSignUpPart1(useRoute()));
    const $isEsqueceuSenha = computed(() =>
      Routes.auth.isEsqueceuSenhaPart1(useRoute()),
    );

    return () => (
      <div class="flex flex-col items-center flex-initial">
        {!$isLogin.value && (
          <v-btn text exact to={loginRoute}>
            fazer login
          </v-btn>
        )}

        {!$isCadastro.value && (
          <div class="flex items-center text-center">
            <span class="text-subtitle">Ainda não possui uma conta?</span>

            <v-btn text class="ml-2" to={signUpRoute}>
              cadastre-se
            </v-btn>
          </div>
        )}

        {!$isEsqueceuSenha.value && (
          <v-btn text to={esqueceuSenhaRoute}>
            Esqueceu sua senha?
          </v-btn>
        )}
      </div>
    );
  },
});
